import React from "react";
import Skeleton from "@mui/material/Skeleton";
import styles from "./SkeletonCampaign.module.scss";
export default function SkelSkeletonCampaignetonUi() {
  return (
    <ul className={styles.container}>
      <li className={styles.skeleton_ui}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.name}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.video}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.status}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.impressions}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.installs}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.cpi}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.clicks}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.cpc}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.ctr}
        />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.name}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.video}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.status}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.impressions}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.installs}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.cpi}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.clicks}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.cpc}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.ctr}
        />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.name}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.video}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.status}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.impressions}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.installs}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.cpi}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.clicks}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.cpc}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.ctr}
        />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.name}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.video}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.status}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.impressions}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.installs}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.cpi}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.clicks}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.cpc}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.ctr}
        />
      </li>
      <li className={styles.skeleton_ui}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.name}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.video}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.status}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.impressions}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.installs}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.cpi}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.clicks}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.cpc}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.ctr}
        />
      </li>
    </ul>
  );
}
