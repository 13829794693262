import * as cookie from "../db/cookies.js";
import moment from "moment";
import axios from "axios";

const expires = moment().add("7", "day").toDate();
export default class AuthService {
  constructor(http, tokenStorage) {
    this.http = http;
    this.tokenStorage = tokenStorage;
  }
  async signup(
    email,
    password,
    studioName,
    teamSize,
    portfolioLink,
    profileImg,
    news
  ) {
    return await this.http
      .axios("/test/common/syncUser", {
        method: "POST",
        data: {
          email,
          password,
          studioName,
          teamSize,
          portfolioLink,
          profileImg,
          news,
          tutorial: false,
          membershipDate: moment().format("YYYY-MM-DD"),
          publishing: false,
          block: false,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          return this.http
            .axios("/auth/signup", {
              method: "POST",
              data: JSON.stringify({
                email,
                password,
                studioName,
                teamSize,
                portfolioLink,
                profileImg,
                news,
              }),
            })
            .then(async (res) => {
              cookie.setCookie("DA_token", res.data.token, {
                expires,
                secure: true,
                sameSite: "none",
              });

              return res;
            })
            .catch((e) => e.response);
        }
      })
      .catch((e) => e.response);
  }
  async login(email, password) {
    let data = await this.http
      .axios("/auth/login", {
        method: "POST",
        data: JSON.stringify({ email, password }),
      })
      .then((res) => {
        cookie.setCookie("DA_token", res.data.token, {
          expires,
          secure: true,
          sameSite: "none",
        });
        return res;
      })
      .catch(console.error);

    return data;
  }

  async invoiceLogin(data) {
    return await this.http
      .axios(`/test/user/logIn`, {
        method: "POST",
        data,
      })
      .then((res) => res)
      .catch((e) => e.response);
  }

  async sendPassword(email) {
    const data = await this.http
      .axios("/email/resetPassword", {
        method: "POST",
        data: JSON.stringify({ email }),
      })
      .then((res) => res)
      .catch(console.error);
    return data;
  }
  async resetPassword(email, password) {
    const token = cookie.getCookie("DA_token");

    const data = await this.http
      .axios("/auth/password", {
        method: "PUT",
        data: JSON.stringify({ email, password }),
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch(console.error);

    return data;
  }
  async modifingInfo(id, password, profileImg, portfolioLink, teamSize) {
    const token = cookie.getCookie("DA_token");

    const data = await this.http
      .axios("/auth/userinfo", {
        method: "PUT",
        data: JSON.stringify({
          id,
          password,
          profileImg,
          portfolioLink,
          teamSize,
        }),
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res)
      .catch(console.error);
    return data;
  }

  async me() {
    const token = cookie.getCookie("DA_token");
    if (token) {
      return this.http.axios("/auth/me", {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  }
  async logout() {
    cookie.deleteCookie("DA_token");
  }
  async checkTurorial() {
    const token = cookie.getCookie("DA_token");
    return await this.http
      .axios("/auth/tutorial", {
        method: "PUT",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => data)
      .catch(console.error);
  }
}
