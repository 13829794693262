import React from "react";
import styles from "./CompletionTitle.module.scss";
const CompletionTitle = ({ appInfo }) => {
  return (
    <div className={styles.container}>
      <div className={styles.firstText}>
        <span className={styles.gameName}>{appInfo.name}&nbsp;</span>
        <span className={styles.completeText}>
          {" "}
          was submitted successfully!
        </span>
      </div>
      <div className={styles.secondText}>
        You can now check your game data in real time.
      </div>
    </div>
  );
};
export default CompletionTitle;
