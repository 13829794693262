import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./SettingBoxs.module.css";
import copyIcon from "../../../img/settingBoxs/copyIcon.png";

import FirstDocModal from "./FirstDocModal/FirstDocModal";
import SecondDocModal from "./SecondDocModal/SecondDocModal";
import ThirdDocModal from "./ThirdDocModal/ThirdDocModal";

import VideoModal from "../../../components/BasicSetting/VideoModal/VideoModal";
import arrowIcon from "../../../img/settingBoxs/arrow.png";
import questionMark from "../../../img/settingBoxs/questionMark.png";
const SettingBoxs = ({
  fourthCheck,
  setFourthCheck,
  nextManagerSetup,
  videoGuidePoint,
  setVideoGuidePoint,
}) => {
  const [isCheckBns, setIsCheckBns] = useState(false);
  const [firstSetting, setFirstSetting] = useState(false);
  const [secondSetting, setSecondSetting] = useState(false);
  const [thirdSetting, setThirdSetting] = useState(false);
  const privacyRef = useRef();
  const [isPrivacyCopy, setIsPrivacyCopy] = useState(false);
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showThirdModal, setShowThirdModal] = useState(false);
  const [firstCheck, setFirstCheck] = useState(false);
  const [secondCheck, setSecondCheck] = useState(false);
  const [thirdCheck, setThirdCheck] = useState(false);
  const privacyCopy = () => {
    const privacyCopy = privacyRef.current;
    privacyCopy.select();

    document.execCommand("copy");
    setIsPrivacyCopy(true);
    setTimeout(() => {
      setIsPrivacyCopy(false);
    }, 1000);
  };
  const firstSettingView = () => {
    setFirstSetting(!firstSetting);
  };
  const secondSettingView = () => {
    setSecondSetting(!secondSetting);
  };
  const thirdSettingView = () => {
    setThirdSetting(!thirdSetting);
  };
  const nextFirstSettingClick = () => {
    setFirstSetting(false);
    setSecondSetting(true);
    setThirdSetting(false);
  };
  useEffect(() => {
    setFirstSetting(true);
    setSecondSetting(false);
    setThirdSetting(false);
  }, []);
  useEffect(() => {
    if (firstCheck && secondCheck && thirdCheck) {
      setFirstSetting(false);
      setSecondSetting(false);
      setThirdSetting(true);
    }
    if (fourthCheck) {
      setFirstSetting(false);
      setSecondSetting(false);
      setThirdSetting(false);
    }
  }, [firstCheck, fourthCheck, secondCheck, thirdCheck]);
  useEffect(() => {
    const firstCheckBox = document?.getElementById("firstCheckBox");
    const secondCheckBox = document?.getElementById("secondCheckBox");
    const thirdCheckBox = document?.getElementById("thirdCheckBox");
    const fourthCheckBox = document?.getElementById("fourthCheckBox");
    if (secondSetting) {
      setTimeout(() => {
        if (localStorage.getItem("firstCheck")) firstCheckBox.checked = true;
        if (localStorage.getItem("secondCheck")) secondCheckBox.checked = true;
        if (localStorage.getItem("thirdCheck")) thirdCheckBox.checked = true;
      }, 50);
    }
    if (thirdSetting) {
      setTimeout(() => {
        if (localStorage.getItem("fourthCheck")) fourthCheckBox.checked = true;
      }, 50);
    }
    if (localStorage.getItem("app_Id")) {
      setIsCheckBns(true);
      setFirstCheck(true);
      setSecondCheck(true);
      setThirdCheck(true);
      setFourthCheck(true);
    }
  }, [
    firstCheck,
    fourthCheck,
    secondCheck,
    secondSetting,
    setFourthCheck,
    thirdCheck,
    thirdSetting,
  ]);
  return (
    <>
      <div className={styles.container}>
        {!videoGuidePoint ? null : (
          <VideoModal
            setVideoGuide={setVideoGuidePoint}
            nextManagerSetup={nextManagerSetup}
          />
        )}
        {!showFirstModal ? null : (
          <FirstDocModal setShowFirstModal={setShowFirstModal} />
        )}
        {!showSecondModal ? null : (
          <SecondDocModal setShowSecondModal={setShowSecondModal} />
        )}
        {!showThirdModal ? null : (
          <ThirdDocModal setShowThirdModal={setShowThirdModal} />
        )}
        <div
          className={
            firstSetting ? styles.firstSetting : styles.inActiveFirstSetting
          }
        >
          {!firstSetting && (
            <>
              <img
                src={arrowIcon}
                alt=""
                className={styles.firstArrow}
                onClick={firstSettingView}
              />
              <div className={styles.firstSettingTitle}>
                1. Log-in to or Create a Facebook Business Manager account.
              </div>
            </>
          )}
          {firstSetting && (
            <>
              <img
                src={arrowIcon}
                alt=""
                className={styles.firstRotateArrow}
                onClick={firstSettingView}
              />
              <img
                src={questionMark}
                alt=""
                className={styles.firstQuestionMark}
                onClick={() => setShowFirstModal(true)}
              />
              <div className={styles.firstSettingTitle}>
                1. Log-in to or Create a Facebook Business Manager account.
              </div>
              <div className={styles.firstSettingFirst}>
                Do you have a Facebook business manager account?
              </div>
              <div className={styles.firstSettingBox}>
                Create a&nbsp;
                <a
                  href="https://developers.facebook.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook Business Manager account.
                </a>
              </div>
              <div className={styles.firstSettingBtnContainer}>
                <button>No</button>
                <button onClick={nextFirstSettingClick}>Yes</button>
              </div>
            </>
          )}
        </div>
        <div
          className={
            secondSetting === true
              ? styles.secondSetting
              : styles.inActiveSecondSetting
          }
        >
          {!secondSetting && (
            <>
              <img
                src={arrowIcon}
                alt=""
                className={styles.secondArrow}
                onClick={secondSettingView}
              />
              <div className={styles.secondSettingTitle}>
                2. Setup your Facebook for Developers Account.
              </div>
            </>
          )}
          {secondSetting && (
            <>
              <img
                src={arrowIcon}
                alt=""
                className={styles.secondRotateArrow}
                onClick={secondSettingView}
              />
              <img
                src={questionMark}
                alt=""
                className={styles.secondQuestionMark}
                onClick={() => setShowSecondModal(true)}
              />
              <div className={styles.secondSettingTitle}>
                2. Setup your Facebook for Developers Account.
              </div>
              <ul className={styles.checkBoxConainer}>
                <div className={styles.firstCheck}>
                  <input
                    type="checkbox"
                    id="firstCheckBox"
                    className={styles.firstCheckBox}
                  />
                  <label
                    htmlFor="firstCheckBox"
                    onClick={() => {
                      localStorage.setItem("firstCheck", true);
                      setFirstCheck(!firstCheck);
                    }}
                  ></label>
                  <div>
                    Head to&nbsp;
                    <a
                      href="https://developers.facebook.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook for Developers
                    </a>
                  </div>
                </div>
                <div className={styles.secondCheck}>
                  <input
                    type="checkbox"
                    id="secondCheckBox"
                    className={styles.secondCheckBox}
                  />
                  <label
                    htmlFor="secondCheckBox"
                    onClick={() => {
                      localStorage.setItem("secondCheck", true);
                      setSecondCheck(!secondCheck);
                    }}
                  ></label>
                  Did you Add Details to your BusinessAccount?
                </div>
                <div className={styles.thirdCheck}>
                  <input
                    type="checkbox"
                    id="thirdCheckBox"
                    className={styles.thirdCheckBox}
                  />
                  <label
                    htmlFor="thirdCheckBox"
                    onClick={() => {
                      localStorage.setItem("thirdCheck", true);
                      setThirdCheck(!thirdCheck);
                    }}
                  ></label>
                  Did you Add Check your App ID?
                </div>
              </ul>
            </>
          )}
        </div>
        <div
          className={
            thirdSetting ? styles.thirdSetting : styles.inActiveThirdSetting
          }
        >
          {!thirdSetting && (
            <>
              <img
                src={arrowIcon}
                alt=""
                className={styles.thirdArrow}
                onClick={thirdSettingView}
              />
              <div className={styles.thirdSettingTitle}>
                3. Complete Developer's Basic settings.
              </div>
            </>
          )}
          {thirdSetting && (
            <>
              <img
                src={arrowIcon}
                alt=""
                className={styles.thirdRotateArrow}
                onClick={thirdSettingView}
              />
              <img
                src={questionMark}
                alt=""
                className={styles.thirdQuestionMark}
                onClick={() => setShowThirdModal(true)}
              />
              <div className={styles.thirdSettingTitle}>
                3. Complete Developer's Basic settings.
              </div>
              <div>
                <div className={styles.fourthCheck}>
                  <input
                    type="checkbox"
                    id="fourthCheckBox"
                    className={styles.fourthCheckBox}
                  />
                  <label
                    htmlFor="fourthCheckBox"
                    onClick={() => {
                      localStorage.setItem("fourthCheck", true);
                      setFourthCheck(!fourthCheck);
                    }}
                  ></label>
                  <div>
                    Did you Switch to Live Mode After Completing{" "}
                    <a
                      href="https://developers.facebook.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Basic Settings?
                    </a>
                  </div>
                </div>

                <div className={styles.privacyBox}>
                  <div className={styles.privacyText}>
                    Don’t have a Privacy Policy? Use ours!
                  </div>
                  <div className={styles.privacyUrl}>
                    <input
                      className={styles.url}
                      ref={privacyRef}
                      value="https://mondayoff.me/privacyPolicy.html"
                      onClick={privacyCopy}
                      readOnly
                    ></input>
                    <img
                      src={copyIcon}
                      alt=""
                      className={styles.copyIcon}
                      onClick={privacyCopy}
                    />
                  </div>
                  <div
                    className={classNames(
                      !isPrivacyCopy
                        ? styles.inactivePopupText
                        : styles.popupText
                    )}
                  >
                    Copied
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SettingBoxs;
