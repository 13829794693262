import { getCookie } from "../db/cookies";

export default class sdkService {
  constructor(http) {
    this.http = http;
  }
  async downSDK() {
    return await this.http
      .axios("/sdk/download", {
        method: "GET",
      })
      .then(window.open(`https://server.mondayoff.me/sdk/download`, "_blank"));
  }
  async downSDK_Test(downloadType) {
    return await this.http
      .axios(`/test/game/sdk?downloadType=${downloadType}`, {
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res) => res)
      .catch((e) => e.response);
  }
  async requestMaxIds(gameName) {
    return await this.http
      .axios(`/test/max/slack?gameName=${gameName}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getCookie("Authentication")}`,
        },
      })
      .then((res) => res)
      .catch((e) => e.response);
  }
}
