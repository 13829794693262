import React from "react";
import styles from "./GameIcon.module.scss";
const GameIcon = ({ appInfo }) => {
  return (
    <div className={styles.container}>
      <img src={appInfo.gameIconUrl} alt="" className={styles.gameIcon} />
    </div>
  );
};

export default GameIcon;
