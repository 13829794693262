import React from "react";
import styles from "./GuideSidebar.module.scss";
import { ReactComponent as Logo } from "../../img/guide/logo.svg";
import { useLocation } from "react-router-dom";
export default function GuideSidebar() {
  const location = useLocation();
  return (
    <section className={styles.container}>
      <Logo className={styles.logo} />
      <div className={styles.guide_update_date}>
        <p>Submission Guide</p>
        <p>Last Edited : 2024. 01. 25</p>
      </div>
      <div className={styles.guide_menu_list}>
        <a
          href="/guide/creation/business"
          data-content={location.pathname.includes("business")}
        >
          <p>1.&nbsp;</p>
          <p>How to Create a Business Manager Account</p>
        </a>
        <a
          href="/guide/regist/developer-appId"
          data-content={location.pathname.includes("developer-appId")}
        >
          <p>2.&nbsp;</p>
          <p>
            How to register your App on Facebook for Developers and create an
            App ID
          </p>
        </a>
        <a
          href="/guide/basic/setting"
          data-content={location.pathname.includes("basic")}
        >
          <p>3.&nbsp;</p>
          <p>Basic Settings setup for developers.</p>
        </a>
        <a
          href="/guide/advanced/setting"
          data-content={location.pathname.includes("advanced")}
        >
          <p>4.&nbsp;</p>
          <p>Advanced Facebook Settings for Developers.</p>
        </a>
        <a
          href="/guide/role/setting"
          data-content={location.pathname.includes("role")}
        >
          <p>5.&nbsp;</p>
          <p>Setup Roles on Facebook for Developers</p>
        </a>
        <a
          href="/guide/sdk/integration"
          data-content={location.pathname.includes("integration")}
        >
          <p>6.&nbsp;</p>
          <p>How to Integrate MondayOFF SDK</p>
        </a>
        <a
          href="/guide/creation/ads-unity"
          data-content={location.pathname.includes("ads-unity")}
        >
          <p>7.&nbsp;</p>
          <p>How to Create Ads in Unity</p>
        </a>
      </div>
    </section>
  );
}
