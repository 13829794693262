import React from "react";
import styles from "./CreationBusiness.module.scss";
import secondImg from "../../img/guide/CreationBusiness/second.png";
import thirdImg from "../../img/guide/CreationBusiness/third.png";
import fourthImg from "../../img/guide/CreationBusiness/fourth.png";
import fifthImg from "../../img/guide/CreationBusiness/fifth.png";
export default function CreationBusiness() {
  return (
    <section className={styles.container}>
      <section className={styles.contents}>
        <h1 className={styles.title}>
          1. How to Create a Business Manager Account
        </h1>
        <section className={styles.process}>
          <div className={styles.first}>
            <p>1. Move to the Facebook Business page and Login&nbsp;</p>(
            <a
              href="https://business.facebook.com/"
              target="_blank"
              rel="noreferrer"
            >
              business.facebook.com
            </a>
            )
          </div>
          <div className={styles.second}>
            <p>
              2. Click the{" "}
              <span data-content="bold">“Create Account button”</span> in the
              upper right corner.
            </p>
            <img src={secondImg} alt="img" />
          </div>
          <div className={styles.third}>
            <p>3. Fill in the information and click on submit.</p>
            <img src={thirdImg} alt="img" />
          </div>
          <div className={styles.fourth}>
            <p>4. Confirm your email and click the “Done” button.</p>
            <img src={fourthImg} alt="img" />
          </div>
          <div className={styles.fifth}>
            <p>5. Nothing else! Your account has been created.</p>
            <img src={fifthImg} alt="img" />
          </div>
        </section>
      </section>
    </section>
  );
}
