import * as cookie from "../db/cookies";

export default class AdsService {
  constructor(http, tokenStorage) {
    this.http = http;
    this.tokenStorage = tokenStorage;
  }
  async getAllGameData() {
    return this.http
      .axios(`/test/game/submmit`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookie.getCookie("Authentication")}`,
        },
      })
      .then((res) => res)
      .catch((e) => e.response);
  }
  async getGameData(gameId) {
    return this.http
      .axios(`/test/game/submmit/one?gameId=${gameId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookie.getCookie("Authentication")}`,
        },
      })
      .then((res) => res)
      .catch((e) => e.response);
  }
  async allAds(email) {
    const token = cookie.getCookie("DA_token");
    const query = `access_token=${token}`;
    return await this.http
      .axios(`/campaign/${email}?${query}`, {
        method: "GET",
      })
      .then((res) => res.data)
      .catch(console.error);
  }
  async insertAppId(appId) {
    const token = cookie.getCookie("DA_token");
    const type = localStorage.getItem("type");
    const genre = localStorage.getItem("genre");
    return await this.http
      .axios(`/campaign/setApp`, {
        method: "POST",
        data: JSON.stringify({ appId, type, genre }),
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        localStorage.setItem("appInfo", JSON.stringify(res.data));
        return res.data;
      });
  }

  async checkPeriodAppId(appId) {
    return await this.http
      .axios(`/campaign/duplication/${appId}`, { method: "GET" })
      .then((res) => res)
      .catch(console.error);
  }
}
