import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect, memo } from "react";
import moment from "moment";
import CreationBusiness from "../pages/CreationBusiness/CreationBusiness";

export default function GuideRouter() {
  return (
    <>
      <Routes>
        <Route path="/guide/creation/business" element={<CreationBusiness />} />
        <Route path="/guide/regist/developer-appId" />
        <Route path="/guide/basic/setting" />
        <Route path="/guide/advanced/setting" />
        <Route path="/guide/role/setting" />
        <Route path="/guide/sdk/integration" />
        <Route path="/guide/creation/ads-unity" />
      </Routes>
    </>
  );
}
